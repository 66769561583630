<template>
    <el-dialog :title="'推荐结果'"
							 width="80%"
							 :before-close="beforeClose"
                             @open="open()"
                             :modal-append-to-body='false'
                             append-to-body
							 >
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-pie-chart"></i> 建议结果
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="plugins-tips">
   
                <a
                    href="https://github.com/lin-xin/vue-schart"
                    target="_blank"
                >vue-schart</a>
            </div>
            <div class="schart-box">
                <div class="content-title">柱状图</div>
                <div id="bar"/>
                <!-- <schart class="schart" canvasId="bar" :options="options1"></schart> -->
            </div>
            <div class="schart-box">
                <div class="content-title">折线图</div>
                <div id="line"/>
                <!-- <schart class="schart" canvasId="line" :options="options2"></schart> -->
            </div>
            <div class="schart-box">
                <div class="content-title">饼状图</div>
                <div id="pie"/>
                <!-- <schart class="schart" canvasId="pie" :options="options3"></schart> -->
            </div>
            <div class="schart-box">
                <div class="content-title">环形图</div>
                <div id="ring"/>
                <!-- <schart class="schart" canvasId="ring" :options="options4"></schart> -->
            </div>
        </div>
    </div>
    </el-dialog>
</template>

<script>
import Schart from 'vue-schart';
export default {
    name: 'basecharts',
    components: {
        Schart
    },
    props: {
		visible: {type: Boolean, default: false, required: true},
		},

    data() {
        return {
            
            options2: {
                type: 'line',
                title: {
                    text: '最近几个月各品类销售趋势图'
                },
                bgColor: '#fbfbfb',
                labels: ['6月', '7月', '8月', '9月', '10月'],
                datasets: [
                    {
                        label: '家电',
                        data: [234, 278, 270, 190, 230]
                    },
                    {
                        label: '百货',
                        data: [164, 178, 150, 135, 160]
                    },
                    {
                        label: '食品',
                        data: [114, 138, 200, 235, 190]
                    }
                ]
            },
            options3: {
                type: 'pie',
                title: {
                    text: '服装品类销售饼状图'
                },
                legend: {
                    position: 'left'
                },
                bgColor: '#fbfbfb',
                labels: ['T恤', '牛仔裤', '连衣裙', '毛衣', '七分裤', '短裙', '羽绒服'],
                datasets: [
                    {
                        data: [334, 278, 190, 235, 260, 200, 141]
                    }
                ]
            },
            options4: {
                type: 'ring',
                title: {
                    text: '环形三等分'
                },
                showValue: false,
                legend: {
                    position: 'bottom',
                    bottom: 40
                },
                bgColor: '#fbfbfb',
                labels: ['vue', 'react', 'angular'],
                datasets: [
                    {
                        data: [500, 500, 500]
                    }
                ]
            }
        };
    },
    watch: {
				visible(newValue) {
                    console.log('visible',newValue)
						if (newValue) {
							console.log('visible',newValue)
						}
				}
		},

    async mounted() {
        console.log()
    //   await this.$store.dispatch('operate/mediaTypeList');
    //   await this.$store.dispatch('operate/areaStationList');
    // console.log('this.$store.state.operate.smartCondition',this.$store.state.operate.smartCondition)
    },

    initEcharts() {
        var echarts = require('echarts');
 
        // 基于准备好的dom，初始化echarts实例
        const bar = this.$echarts.init(document.getElementById('bar'));
        console.log('bar',bar)
        // 绘制图表
         const options1 = {
                type: 'bar',
                title: {
                    text: '最近一周各品类销售图'
                },
                bgColor: '#fbfbfb',
                labels: ['周一', '周二', '周三', '周四', '周五'],
                datasets: [
                    {
                        label: '家电',
                        fillColor: 'rgba(241, 49, 74, 0.5)',
                        data: [234, 278, 270, 190, 230]
                    },
                    {
                        label: '百货',
                        data: [164, 178, 190, 135, 160]
                    },
                    {
                        label: '食品',
                        data: [144, 198, 150, 235, 120]
                    }
                ]
            };
        bar.setOption(option1)
      },

    open(){
        console.log('do open')
        this.$nextTick(() => {
        //  执行echarts方法
          this.initEcharts()
        })
      },
};
</script>

<style scoped>
.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}
</style>